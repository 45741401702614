import React, { Fragment } from 'react'
import "./DatePicker.css"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

const DatePicker = (props) => {
    const getDate = () => {
        if (props.value != null) {
            console.log(props.value)
            return dayjs(props.value)
        } else {
            const dateToday = new Date()
            return dayjs(dateToday)
        }
    }

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                    components={[
                        'MobileDateTimePicker',
                    ]}>
                    <MobileDateTimePicker
                        readOnly={props.readOnly}
                        className='date__picker__container'
                        format="LLL"
                        value={getDate()}
                        onChange={(e) => props.onChange(props.formType, props.tag, e)}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <div className="date__picker__space" />
        </Fragment>
    )
}

export default DatePicker
