import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { AppConstants } from '../../../utils/Constants'

function GeneralModal(props) {

    const save = () => {
        props.setShowGeneralModal(false)
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={save}>{"close"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default GeneralModal