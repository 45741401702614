import React, { Fragment, useState } from 'react'
import './HistorySessionModal.css';
import { Button, Modal } from 'react-bootstrap'
import FullScreenLoader from '../../Loaders/FullScreenLoader';

function HistorySessionModal(props) {

    const exit = () => {
        props.setHistorySessionModal(!props.show)
    }

    const formatTime = (dateValue) => {
        const date = new Date(dateValue)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        const day = date.toLocaleDateString("en-US", { weekday: 'long' });
        const month = date.toLocaleDateString("en-US", { month: 'long' });
        const dayNumber = date.toLocaleDateString("en-US", { day: 'numeric' });
        const year = date.toLocaleDateString("en-US", { year: 'numeric' });
        const dateFormatted = day + " " + month + " " + dayNumber + ", " + year

        var strTime = dateFormatted + ", " + hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const selected = async (value) => {
        props.setHistorySessionModal(false)
        props.setHistorySessionLoader(true)
        props.sessionSelected(value)
    }

    return (
        <Fragment>
            {
                props.showLoader &&
                <FullScreenLoader />
            }


            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {"Previous Notes"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='history_modal_container'>
                        {props.sessionPrevious.map((value, index) =>
                            <div onClick={(e) => selected(value.sessionid)}><p>- {formatTime(value.from)}</p></div>
                        )}
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={exit}>{"Ok"}</Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}

export default HistorySessionModal