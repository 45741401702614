import React, { useEffect, useState, useContext, Fragment } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import './SendInvoiceModal.css';
import Loader from 'react-loader-spinner'
import AuthContext from '../../../store/auth-context';
import API from '../../../utils/API';

function SendInvoiceModal(props) {

    const [loadingModal, setLoadingModal] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [email, setEmail] = useState("");
    const [confirmModalText, setConfirmModalText] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [canSendInvoice, setCanSendInvoice] = useState(false);
    const authCtx = useContext(AuthContext)

    useEffect(() => {
        setLoadingModal(true)
        fetchData()
    }, []);

    const fetchData = async () => {
        setLoadingModal(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/provider/email`, config);
            console.log(response)
            if (response.data.status == 200) {
                setEmail(response.data.message.result.email)
            }
            setLoadingModal(false)
        } catch (e) {
            setLoadingModal(false)
        }
    }

    /*
    Check The dateTo is one month later and the day is greater than authCtx.invoiceDayLimit (usually 10)
    */
    const checkCanSendInvoice = () => {
        const today = new Date();
        const dateTo = new Date(props.servicePeriodTo + ' 23:59:59');
        var result = false
        if (today.getYear() > dateTo.getYear() || today.getMonth() > dateTo.getMonth()) {
            const difference = today.getMonth() - dateTo.getMonth()
            const day = today.getDate()
            if (today.getYear() > dateTo.getYear() || difference > 1) {
                result = true
            } else if (difference == 1 && day >= authCtx.invoiceDayLimit) {
                result = true
            }
        }
        if (result) {
            setConfirmModalText("Please confirm you wish to Submit your Contractor Invoice to Genesis for payment and email a copy to the email address below for your records.")
        } else {
            let ordinal = '';
            let units = authCtx.invoiceDayLimit % 10;
            let tens = authCtx.invoiceDayLimit % 100;
            if (units == 1 && tens != 11) {
                ordinal = authCtx.invoiceDayLimit + "st";
            } else if (units == 2 && tens != 12) {
                ordinal = authCtx.invoiceDayLimit + "nd";
            } else if (units == 3 && tens != 13) {
                ordinal = authCtx.invoiceDayLimit + "rd";
            } else {
                ordinal = authCtx.invoiceDayLimit + "th";
            }
            setConfirmModalText("Submitting of invoices is not available until the " + ordinal + " of the month.")
        }
        setCanSendInvoice(result)
    }

    const save = () => {
        checkCanSendInvoice()
        setIsPreview(false)
        setConfirmModal(true)
    }

    const preview = () => {
        setConfirmModalText("Please confirm you wish to preview your Contractor Invoice by having it emailed to the address below.")
        setCanSendInvoice(true)
        setIsPreview(true)
        setConfirmModal(true)
    }

    const exit = () => {
        props.setShowSendInvoice(false)
    }

    const saveConfirm = () => {
        setConfirmModal(false)
        if (canSendInvoice) {
            props.sendInvoiceTo(email, isPreview)
        } else {
            setConfirmModal(false)
            props.setShowSendInvoice(false)
        }
    }

    const exitConfirm = () => {
        setConfirmModal(false)
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <Fragment>
            <Modal
                show={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                {!confirmModal &&
                    <Fragment>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {"Contractor Invoice"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                loadingModal &&
                                <Loader
                                    type="Oval"
                                    color="#025959"
                                    height={50}
                                    width={50}
                                />
                            }
                            {
                                !loadingModal &&
                                <Fragment>
                                    <p>Click <b>Preview</b> to have an PDF invoice created for the Service Record you are viewing and emailed to the email address below.</p>
                                    <p>Click <b>Submit</b> to have an PDF invoice created for the Service Record you are viewing and emailed to the email address below as well as having your invoice Submitted to Genesis for payment.</p>
                                    <div class="form-group">
                                        <label>Destination Email:</label>
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                value={email}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </div>
                                </Fragment>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={exit} variant="secondary">{"Cancel"}</Button>
                            <Button onClick={preview}>{"Preview"}</Button>
                            <Button onClick={save}>{"Submit"}</Button>
                        </Modal.Footer>
                    </Fragment>
                }
            </Modal>

            <Modal
                show={confirmModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {isPreview ? "Preview Contractor Invoice" : "Submit Contractor Invoice"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Fragment>
                        <p>{confirmModalText} </p>
                        <p><strong>{canSendInvoice && email}</strong></p>
                    </Fragment>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={exitConfirm} variant="secondary">{"Cancel"}</Button>
                    <Button onClick={saveConfirm}>{"Confirm"}</Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}

export default SendInvoiceModal
