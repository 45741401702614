import React, { useState, useEffect } from 'react';

const UserContext = React.createContext({
    showCovidForm: false,
    refreshSessionList: false,
    isShowingModal: false,
    recordServiceItemIndex: -1,
    recordServiceItemRef: -1,
    unreadNotifications: false,
    setRefreshSessionList: (state) => { },
    updateCovidFormState: (state) => { },
    setUnreadNotifications: (state) => { },
});

export const UserContextProvider = (props) => {
    const [showCovidForm, setshowCovidForm] = useState(false);
    const [refreshSessionList, setRefreshSessionList] = useState(false);
    const [isShowingModal, setShowingModal] = useState(false);
    const [recordServiceItemIndex, setRecordServiceItemIndex] = useState(-1);
    const [recordServiceItemRef, setRecordServiceItemRef] = useState(-1);
    const [unreadNotifications, setUnreadNotifications] = useState(false);

    const updateCovidFormHandler = (state) => {
        setshowCovidForm(state);
    };

    return (
        <UserContext.Provider
            value={{
                showCovidForm: showCovidForm,
                refreshSessionList: refreshSessionList,
                isShowingModal: isShowingModal,
                recordServiceItemIndex: recordServiceItemIndex,
                recordServiceItemRef: recordServiceItemRef,
                unreadNotifications: unreadNotifications,
                setShowingModal: setShowingModal,
                setRefreshSessionList: setRefreshSessionList,
                updateCovidFormState: updateCovidFormHandler,
                setRecordServiceItemIndex: setRecordServiceItemIndex,
                setRecordServiceItemRef: setRecordServiceItemRef,
                setUnreadNotifications: setUnreadNotifications,
            }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext;