import React, { useEffect } from 'react'
import './DropDownMenu.css';
import { Form } from 'react-bootstrap';

const DropDownMenu = (props) => {

    const arrayOptions = ["R.S.A", "B.T.A", "B.T", "E.A.T", "P.S.Y"]

    useEffect(() => {
        if (props.titles != null && props.titles.length > 0) {
            var containsDefault = false;
            props.titles.map((value, index) => {
                let id = Object.keys(value)[0]
                let idInt = parseInt(id)
                if (props.value == idInt) {
                    containsDefault = true;
                }
            })
            if (!containsDefault) {
                const value = props.titles[0]
                props.dropDownUpdatedDefault(Object.keys(value)[0])
            }
        }
    }, []);

    const getOptions = () => {
        if (props.titles != null) {
            return props.titles.map((value, index) => {
                let id = Object.keys(value)[0]
                let idInt = parseInt(id)
                return props.value == idInt ?
                    <option selected value={idInt}>{value[idInt]}</option> :
                    <option value={idInt}>{value[idInt]}</option>
            }
            )
        }
    }

    return (
        <div>
            <Form.Select
                disabled={props.disabled}
                aria-label="Provider Title"
                onChange={(e) => props.onChange(props.formType, props.tag, e)}>
                {getOptions()}
            </Form.Select>
        </div>
    )
}

export default DropDownMenu
