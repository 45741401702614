import React, { useState, useEffect } from 'react';

const AuthContext = React.createContext({
  userName: "",
  isLoggedIn: false,
  authHeader: "",
  invoiceDayLimit: "",
  onLogout: () => { },
  onLogin: (token, userName, invoiceDayLimit) => { }
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authHeader, setAuthHeader] = useState("");
  const [userName, setUserName] = useState("");
  const [invoiceDayLimit, setInvoiceDayLimit] = useState(5);


  useEffect(() => {
    const userToken = localStorage.getItem('isLoggedIn');
    const userName = localStorage.getItem('userName');
    const invoiceDayL = localStorage.getItem('invoiceDayLimit');
    if (userToken != null && userToken.length > 0) {
      setAuthHeader("Bearer " + userToken);
      setIsLoggedIn(true);
    }
    if (userName != null) {
      setUserName(userName)
    }
    if (invoiceDayL != null) {
      setInvoiceDayLimit(invoiceDayL)
    }

  }, []);

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userName');
    setIsLoggedIn(false);
  };

  const loginHandler = (token, userName, invoiceDayLimit) => {
    if (token != null) {
      localStorage.setItem('isLoggedIn', token);
      setAuthHeader("Bearer " + token);
      setIsLoggedIn(true);
    }

    if (userName != null) {
      localStorage.setItem('userName', userName);
      setUserName(userName)
    }

    if (invoiceDayLimit != null) {
      localStorage.setItem('invoiceDayLimit', invoiceDayLimit);
      setInvoiceDayLimit(setInvoiceDayLimit)
    }

  };

  return (
    <AuthContext.Provider
      value={{
        userName: userName,
        isLoggedIn: isLoggedIn,
        authHeader: authHeader,
        invoiceDayLimit: invoiceDayLimit,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
