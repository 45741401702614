import React from 'react'
import "./PrimaryButton.css"

function PrimaryButton(props) {
    const isButtonDisabled = props.disabled != null ? props.disabled : false
    const style = isButtonDisabled ? "primary__button__disabled" : "primary__button"

    return (
        <div className="primary__button__container">
            <button
                className={style}
                disabled={props.disabled != null ? props.disabled : false}
                onClick={props.handler}>
                {props.title}
            </button>
        </div>
    )
}

export default PrimaryButton
