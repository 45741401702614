import React, { useEffect, useContext, useMemo, useState } from 'react'
import { Accordion } from 'react-bootstrap';
import ReactQuill from "react-quill";
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import 'react-quill/dist/quill.snow.css';
import './TextEditorForm.css';
import CueDropDown from '../CueDropDown/CueDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import useLongPress from '../../../utils/useLongPress';

function TextEditorForm(props) {

    var containerId = "toolbar" + props.index;
    var reactQuillRef = null;
    var isRemarkContainerClosed = true;
    const [mouduleTest, setModuleTest] = useState({});

    useEffect(() => {
        setModuleTest(
            {
                toolbar: {
                    container: `#${containerId}`,
                    handlers: {
                        undo: undoChange,
                        redo: redoChange,
                        save: saveRemark,
                        remove: removeRemark,
                        onFocus: handleFocus,
                        onBlur: handleBlur,
                    }
                },
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true
                }
            }
        )
    }, []);


    const onLongPress = () => {
        saveRemark()
    };

    const onClick = () => {
        saveRemark()
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);


    const saveRemark = () => {
        reactQuillRef.getEditor().blur()
        props.saveRemark(props.index, true)
    }

    const removeRemark = () => {
        props.deleteRemarkAction(props.index)

    }

    const undoChange = () => {
        if (reactQuillRef != null) {
            reactQuillRef.getEditor().history.undo();
        }
    }

    const redoChange = () => {
        if (reactQuillRef != null) {
            reactQuillRef.getEditor().history.redo();
        }
    }

    const changeValueOnEditor = (type, index, e) => {
        const cleanString = e.replaceAll("<p>", "")
            .replaceAll("</p>", "<br/>")
            .replaceAll("<br>", "")
            .replaceAll("’", "'");
        props.onChange(type, index, cleanString)
        return true
    }

    const containerChangeState = (index, event) => {
        isRemarkContainerClosed = !isRemarkContainerClosed
        if (isRemarkContainerClosed) {
            if (reactQuillRef != null) {
                reactQuillRef.getEditor().blur();
            }
        }
        return true
    }

    const handleFocus = (range, source, editor) => {
        if (!isRemarkContainerClosed) {
            props.onFocus(props.index)
        }
    }

    const handleBlur = (range, source, editor) => {
        if (!isRemarkContainerClosed) {
            props.onBlur(props.index)
        }
    }

    return (
        <div className="text__editor__container">
            {
                props.type != 3 && <Accordion>
                    <Accordion.Item eventKey="1" >
                        <Accordion.Header
                            onClick={(e) => containerChangeState(props.index, e)}
                        >{props.heading}</Accordion.Header>
                        <Accordion.Body >
                            <div className="formEditorContainer">
                                <div className='icons__container'>
                                    <CueDropDown
                                        onChange={props.onChange}
                                        defaultValue={props.cue}
                                        index={props.index}
                                        tag={"cue"}
                                        title="Verbal"
                                    />
                                    <CueDropDown
                                        onChange={props.onChange}
                                        defaultValue={props.cue2}
                                        index={props.index}
                                        tag={"cue2"}
                                        title="Non-Verbal"
                                    />


                                    <div className="SaveIcon">
                                        <SaveIcon {...longPressEvent} className="toolbar_custom_icon" />
                                    </div>

                                    {
                                        (props.type == 4 || props.type == 5) &&
                                        <DeleteForeverIcon className="toolbar_custom_icon" onClick={removeRemark} />
                                    }
                                </div>
                                <div className="text-editor">
                                    <div className='custom__container__toolbar' id={containerId}>
                                        <span className="ql-formats">
                                            <button className="ql-bold" />
                                            <button className="ql-italic" />
                                            <button className="ql-underline" />
                                            <button className="ql-strike" />
                                        </span>
                                        <span className="ql-formats">
                                            <button className="ql-undo">
                                                <UndoIcon />
                                            </button>
                                            <button className="ql-redo">
                                                <RedoIcon />
                                            </button>
                                        </span>
                                    </div>
                                    <ReactQuill
                                        ref={(el) => {
                                            if (el != null) {
                                                reactQuillRef = el
                                            }
                                        }}
                                        className="formEditor"
                                        theme="snow"
                                        modules={mouduleTest}
                                        value={props.value}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={(e) => changeValueOnEditor("content", props.index, e)}
                                    />
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            }

            {
                (props.type == 3) &&
                <div className='remark_disabled_container'>
                    <div className='remark__disabled__text'>
                        <p>{props.heading}</p>
                    </div>
                    <div onClick={(e) => props.addRemarkAction(props.index)} className='remark__disabled__icon'>
                        <AddCircleOutlineIcon />
                    </div>
                </div>
            }

        </div>
    )
}

export default TextEditorForm
