import React from 'react'
import "./FullScreenLoader.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


function FullScreenLoader() {
    return (
        <div className="loader__background__transparent">
            <div className="loader__spinner">
                <Loader
                    type="Oval"
                    color="#025959"
                    height={80}
                    width={80}
                />
            </div>
        </div>
    )
}

export default FullScreenLoader
