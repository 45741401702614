import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import './App.css';
import Home from './views/Home/Home';
import Login from './views/Login/Login';
import CovidForm from './views/CovidForm/CovidForm';
import AuthContext from './store/auth-context';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import NewSession from './views/Session/CreateSession/NewSession';
import EditSession from './views/Session/EditSession/EditSession';
import ServiceRecord from './views/ServiceRecord/ServiceRecord';
import EditServiceRecord from './views/EditServiceRecord/EditServiceRecord';
import Notifications from './views/Notifications/Notifications';
import SingleSignOn from './views/SingleSignOn/SingleSignOn';
import UpdatePassword from './views/UpdatePassword/UpdatePassword';

function App() {
  const context = useContext(AuthContext)

  return (
    <div className="App">
      <Router>
        {!context.isLoggedIn &&
          <Switch>
            <Route path="/sso">
              <SingleSignOn />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        }

        {context.isLoggedIn &&
          <ScrollToTop>
            <Switch>
              <Route path="/covidForm">
                <CovidForm />
              </Route>
              <Route path="/createNote">
                <NewSession />
              </Route>
              <Route path="/editSession">
                <EditSession />
              </Route>
              <Route path="/serviceRecord">
                <ServiceRecord />
              </Route>
              <Route path="/editServiceRecord">
                <EditServiceRecord />
              </Route>
              <Route path="/notificationScreen">
                <Notifications />
              </Route>
              <Route path="/updateUserPassword">
                <UpdatePassword/>
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </ScrollToTop>
        }
      </Router>
    </div>
  );
}

export default App;
