import React, { useState, Fragment, useEffect, useContext } from 'react'
import './ServiceRecordItem.css';
import { TotalTitles } from './ServiceRecordTitles';

function ServiceRecordTotalItem(props) {

    const [totalList, setTotalList] = useState([]);

    useEffect(() => {
        if (props.value instanceof Array) {
            setTotalList(props.value)
        } else if (props.value instanceof Object) {
            const list = [props.value]
            setTotalList(list)
        }
    }, []);

    return (
        <Fragment>
            {totalList.map((value, index) => (
                <div className='record__session_container' key={`${props.title}${index}`}>
                    <p className='record_session_title_provider'>{props.title}:</p>
                    {props.title == TotalTitles.TRAVEL_MILEAGE ?
                        <p className='record_session_description_provider'>{value.mileage}Kms @ ${value.rate}/Km</p> :
                        props.title == TotalTitles.ACTIVITY_MILEAGE ?
                            <p className='record_session_description_provider'>{value.mileage}Kms @ ${value.rate}/Km</p> :
                            props.title == TotalTitles.ACTIVITY_EXPENSES ?
                                <div /> :
                                <p className='record_session_description_provider'>{value.hours}Hrs @ ${value.rate}/Hr</p>
                    }
                    {
                        props.title == TotalTitles.ACTIVITY_EXPENSES ?
                            <p className='record_session_title_total'>${value.amount}</p> :
                            <p className='record_session_title_total'>${value.total}</p>
                    }

                </div>
            ))}

        </Fragment>
    )
}

export default ServiceRecordTotalItem