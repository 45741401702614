import React from 'react'
import './ToastUI.css';

const ToastUI = (props) => {
    return (
        <div className='toast__container__success'>
            <p className='toast__title'>{props.title}</p>
            <p className='toast__description'>{props.description}</p>
        </div>
    )
}

export default ToastUI
