import React, { useContext, useState, useEffect } from 'react'
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import API from '../../utils/API';
import "./CovidForm.css"
import UserContext from '../../store/user-context';
import AuthContext from '../../store/auth-context';
import PrimaryButton from '../../components/Button/PrimaryButton';
import { useHistory } from 'react-router-dom'
import RadioButton from '../../components/RadioButton/RadioButton';
import CloseIcon from '@mui/icons-material/Close';


function CovidForm() {
    const history = useHistory();
    const [questionList, setQuestionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext)

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get("/v2/covidForm", config);
            unpdateQuestionList(response.data.message.result.questions)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const unpdateQuestionList = (questions) => {
        const tempList = []
        for (let i = 0; i < questions.length; i++) {
            const question = questions[i]["question" + (i + 1)]
            tempList.push(
                {
                    question: question,
                    checkId: i,
                    answer: ""
                }
            )
        }
        //console.log(tempList)
        setQuestionList(tempList)
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const responseList = []
        var valid = true
        for (let i = 0; i < questionList.length; i++) {
            const questionKey = "question" + (i + 1)
            const questionValue = questionList[i].answer
            if (questionValue === '') {
                valid = false
            }
            responseList.push(
                {
                    [questionKey]: questionValue,
                }
            )
        }

        if (valid) {
            sendData(responseList)
        } else {
            //console.log("error on form")
        }
    }

    const sendData = async (responseList) => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let date = new Date().toISOString();
            let data = {
                "datetime": date,
                "answers": responseList
            }
            userCtx.updateCovidFormState(false)
            let response = await API.post("/v2/covidForm", data, config);
            setIsLoading(false)
            history.push("/");
        } catch (e) {
            //console.log(e)
            setIsLoading(false)
        }
    }

    const closeScreenHandler = () =>{
        userCtx.updateCovidFormState(false)
        history.push("/");
    }

    const handleChange = (event) => {
        let separator = event.target.value.indexOf("-")
        let index = event.target.value.substring(0, separator)
        let value = event.target.value.substring(separator + 1, event.target.length)
        questionList[index].answer = value
    };

    return (
        <div className="covidForm__container">
            {
                isLoading &&
                <FullScreenLoader />
            }


            <div className="covidForm__form">
                <div className="covidForm__close__icon__container">
                    <CloseIcon onClick={closeScreenHandler} className="covidForm__close__icon"/>
                </div>

                <div className="screen__title">Please answer the following questions</div>
                {questionList.map(question => (
                    <div key={question.checkId}>
                        <div className="covidForm__question">{question.question}</div>
                        <div className="covidForm__radio__container" onChange={handleChange}>
                            <RadioButton
                                text="Yes"
                                value={question.checkId + "-yes"}
                                name={question.checkId + "id"}
                            />

                            <RadioButton
                                text="No"
                                value={question.checkId + "-no"}
                                name={question.checkId + "id"}
                            />
                        </div>
                    </div>
                ))}

                <PrimaryButton
                    title="Confirm"
                    handler={submitHandler}
                />
            </div>
        </div>
    )
}

export default CovidForm