import React, { useState } from 'react'
import Toolbar from '../../../components/Toolbar/Toolbar'
import DatePicker from '../../../components/UI/DatePicker/DatePicker';
import InputForm from '../../../components/UI/InputForm/InputForm';
import "./NewSession.css"

function NewSession() {
    const [timeStartInput, setTimeStartInput] = useState(Date());
    const [timeEndInput, setTimeEndInput] = useState(Date());

    return (
        <div>
            <Toolbar 
                enableBackNavigation = {true}
                fixedToolBar = {true}
                titleScreen = {"New Session"}
                showNewSessionIcons = {true}
            />
            <br />
            <div className="form__container">
                <div className="form_input_container">
                    <h5>Client</h5>
                    <InputForm
                        type = {"Text"}
                        placeholder = {"Client"}
                    />
                    <br />
                    <h5>Start</h5>
                    <DatePicker
                        value={timeStartInput}
                        onChange={setTimeStartInput}
                    />
                    <br />
                    <h5>End</h5>
                    <DatePicker
                        value={timeEndInput}
                        onChange={setTimeEndInput}
                    />
                    <br />
                    <h5>Activity Mileage</h5>
                    <InputForm
                        type = {"Number"}
                        placeholder = {"0"}
                    />
                    <br />
                    <h5>Activity Expenses</h5>
                    <InputForm
                        type = {"Number"}
                        placeholder = {"0"}
                    />
                    <br />
                    <h5>Next Session date</h5>
                    <DatePicker
                        value={timeEndInput}
                        onChange={setTimeEndInput}
                    />
                    <br />
                </div>
            </div>

        </div>
    )
}

export default NewSession
