import React from 'react'
import "./FloatingButton.css"
import CreateIcon from '@mui/icons-material/Create';

function FloatingButton(props) {
    return (
        <button
            type="button"
            className="float__button"
            onClick={(e) => props.onClick(!props.modalState)}
        >
            <CreateIcon className="float__button__icon" />
        </button>
    )
}

export default FloatingButton
