import React from 'react'
import InputForm from '../InputForm/InputForm'
import './ActivityInput.css';

function ActivityInput(props) {

    const getInput = (readonly) => {
        return <InputForm
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            tag={props.tag}
            formType={props.formType}
            readonly={readonly}
        />
    }
    if (props.exclude == 0) {
        return getInput(false)
    } else if (props.exclude == 1) {
        return (
            <div className='input__form__style'>
                <p>Not available</p>
            </div>
        )
    } else {
        return getInput("readonly")
    }
}

export default ActivityInput