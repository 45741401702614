import React from 'react';
import { Button, Modal } from 'react-bootstrap'

function DeleteSessionModal(props) {

    const save = () => {
        props.deleteHandler()
    }

    const exit = () => {
        props.setShowDeleteModal(!props.show)
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"Session Note Delete"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to DELETE this note? This operation cannot be un-done! </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={exit} variant="secondary">{"cancel"}</Button>
                <Button onClick={save}>{"delete"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSessionModal;
