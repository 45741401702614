import React, { Fragment } from 'react'
import HorizontalDivider from '../../Divider/HorizontalDivider'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextEditorForm from '../../../components/UI/TextEditorForm/TextEditorForm';
import './RemarkList.css';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

//https://www.freecodecamp.org/news/how-to-add-drag-and-drop-in-react-with-react-beautiful-dnd/
function RemarkList(props) {
    return (
        <Fragment>
            <HorizontalDivider />
            <DragDropContext onDragEnd={props.handleOnDragEnd}>
                <Droppable droppableId="remarksArea">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                props.sessionRemarks.map((value, index) =>
                                    value.type == 1 ?
                                        <p className="form__title__remarks" key={index + value.sncid.toString()} >{value.heading}</p> :
                                        <Draggable key={index + value.sncid.toString()} draggableId={index + value.sncid.toString()} index={index}>
                                            {(provided) => (
                                                <div
                                                    //onFocus={(e) => props.remarkFocusChange(index, e)}
                                                    className='item__list__container'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <DragIndicatorIcon className='draggable__icon' />
                                                    <TextEditorForm
                                                        type={value.type}
                                                        heading={value.heading}
                                                        index={index}
                                                        value={value.content}
                                                        onChange={props.remarkChange}
                                                        saveRemark={props.saveRemark}
                                                        onFocus={props.remarkFocusChange}
                                                        onBlur={props.remarkFocusOut}
                                                        cue={value.cue}
                                                        cue2={value.cue2}
                                                        addRemarkAction={props.addOptionalRemarkModal}
                                                        deleteRemarkAction={props.deleteOptionalRemarkModal}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                )
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </Fragment>
    )
}

export default RemarkList
