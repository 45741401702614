import React, { useContext, useState, useEffect } from 'react'
import './NextSessionModal.css';
import { Button, Modal } from 'react-bootstrap'
import RadioButton from '../../RadioButton/RadioButton';
import API from '../../../utils/API';
import AuthContext from '../../../store/auth-context';

function NextSessionModal(props) {

    const [typeSet, setTypeSet] = useState();
    const authCtx = useContext(AuthContext);
    const newOptions = [
        "Rehab Session",
        "Team Meeting",
        "Clinical Meeting",
        "Cancellation",
        "Billable Work"
    ]

    const save = () => {
        if (typeSet != null && typeSet != "") {
            const body = {
                snid: props.sessionId,
                type: typeSet
            }
            convertSession(body)
        }
    }

    const convertSession = async (bodyData) => {
        //console.log(bodyData)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.put("/v2/sessions/note/convert ", bodyData, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                //console.log("session created")
                props.sessionCreated(response.data.message.result.snid)
            } else if (statusCode >= 400 && statusCode < 500) {
            }
        } catch (e) {
        }

    }

    const exit = () => {
        props.setShowNextModal(!props.show)
    }

    const handleSetChange = (event) => {
        setTypeSet(event.target.value)
    };

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"CONVERT Next Note"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Select one of the following: </p>
                <div className="modal_radio__container" onChange={handleSetChange}>
                    {newOptions.map((value, index) => (
                        <RadioButton
                            text={value}
                            // value={21 + index}
                            value={value}
                            name={"type123"}
                        />
                    ))}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={exit} variant="secondary">{"cancel"}</Button>
                <Button onClick={save}>{"convert"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NextSessionModal;
