import React, { useState, useContext, useEffect } from 'react'
import Card from '../../components/UI/Card/Card'
import './NotificationList.css';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from 'react-bootstrap';
import API from '../../utils/API';
import AuthContext from '../../store/auth-context';

function NotificationList(props) {

    const arrayOptions = ["Unread", "Read", "All"]
    const authCtx = useContext(AuthContext);
    const [listNotification, setListNotification] = useState([]);
    const [filterListNotification, setFilterListNotification] = useState([]);
    const [filterSelected, setFilterSelected] = useState(0);

    useEffect(() => {
        console.log(props.notifications)
        setListNotification(props.notifications)
        var filterList = props.notifications.filter((value) => {
            return value.read === false
        })
        setFilterListNotification(filterList)
    }, []);

    const getDateFormated = (dateString) => {
        const date = new Date(dateString)
        const day = date.toLocaleDateString("en-US", { weekday: 'long' });
        const month = date.toLocaleDateString("en-US", { month: 'long' });
        const dayNumber = date.toLocaleDateString("en-US", { day: 'numeric' });
        const year = date.toLocaleDateString("en-US", { year: 'numeric' });
        const dateFormatted = day + " " + month + " " + dayNumber + ", " + year
        return dateFormatted
    }

    const onFilterChange = (event) => {
        const selected = Number(event.target.value)
        setFilterSelected(selected)
        if (selected === 2) {
            setFilterListNotification(listNotification)
        } else {
            const read = selected === 1
            var tempList = listNotification.filter((value) => {
                return value.read === read
            })
            setFilterListNotification(tempList)
        }
    }

    const getOptions = () => {
        return arrayOptions.map((value, index) => {
            return index == 0 ?
                <option selected value={index}>{value}</option> :
                <option value={index}>{value}</option>
        })
    }

    const updateStateNotification = (indexNotification, idNotification) => {
        var remoteValue = false
        const newListNotification = listNotification.map(notification => {
            if (notification.id === idNotification) {
                remoteValue = !notification.read
                notification.read = !notification.read
            }
            return notification
        })
        setListNotification(newListNotification)

        console.log(filterSelected)
        if (filterSelected === 2) {
            setFilterListNotification(newListNotification)
        } else {
            const read = filterSelected === 1
            var tempList = newListNotification.filter((value) => {
                return value.read === read
            })
            setFilterListNotification(tempList)
        }
        updateRemoteValue(idNotification, remoteValue)
    }

    const updateRemoteValue = async (id, value) => {
        const bodyData = {
            read: value,
        }
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.put(`/v2/notifications/provider/${id}`, bodyData, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                console.log(response)
            } else if (statusCode >= 400 && statusCode < 500) {
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='noti__container'>
            <Form.Control as="select"
                onChange={(e) => onFilterChange(e)}>
                {getOptions()}
            </Form.Control>

            <br />
            {
                filterListNotification.map((notification, index) => (
                    <div key={notification.id}>
                        <Card
                            style={{ marginBottom: '1rem' }}>
                            <div className='base__container'>
                                <div className='header__container'>
                                    <div className={notification.read ? 'circle__grey' : 'circle__red'} />
                                    <p>{getDateFormated(notification.created)}</p>
                                    <div className='card__icon'
                                        onClick={(e) => updateStateNotification(index, notification.id)}>
                                        {notification.read ? <CheckIcon /> : <CloseIcon />}
                                    </div>
                                </div>
                                <hr className='divider__style' />
                                <p>{notification.notification}</p>
                            </div>

                        </Card>
                    </div>
                ))
            }
        </div>
    )
}

export default NotificationList