import React, { useRef, useState, useContext } from 'react'
import "./Login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../../store/auth-context';
import API from '../../utils/API';
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import UserContext from '../../store/user-context';
import logoGenesis from '../../assets/logogenesis.jpeg'
import InputPassword from '../../components/UI/InputPassword/InputPassword';
import { AppConstants } from '../../utils/Constants';

function Login() {
    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext)
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = (event) => {
        event.preventDefault();
        if (passwordInput.trim().length <= 5 || emailInput.trim().length <= 0) {
            showLoginError("Invalid Credentials values")
        } else {
            validateUserCredentials(emailInput, passwordInput)
        }
    };

    const validateUserCredentials = async (email, password) => {
        setIsLoading(true)
        try {
            const data = {
                "username": email,
                "password": password,
                "appver": AppConstants.app_version
            }
            const response = await API.post("/v2/login", data);
            if (response.data.status == 200 && response.data.message.result.token != null) {
                setIsLoading(false)
                userCtx.updateCovidFormState(true)
                authCtx.onLogin(response.data.message.result.token, email, response.data.message.options.invoice_day)
            } else {
                showLoginError("Invalid Credentials")
            }
        } catch (e) {
            showLoginError("NetWork Error")
        }
    }

    const showLoginError = (message) => {
        setIsLoading(false)
        toast.error(
            message,
            { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
        );
    }

    return (
        <div className="login_container">
            <ToastContainer
                hideProgressBar={true}
                draggable />
            <div className="login_form">
                {/* <p className="login_logo">LOGO</p> */}
                <img src={logoGenesis} className="logo_img" />
                <logoGenesis />
                <p className="login_instructions">Login to your account</p>
                <input
                    value={emailInput}
                    onInput={e => setEmailInput(e.target.value)}
                    ref={emailInputRef}
                    className="input_form"
                    type="email"
                    placeholder="Username" />
                <InputPassword
                    passwordInput={passwordInput}
                    setPasswordInput={setPasswordInput}
                    passwordInputRef={passwordInputRef}
                />
                <button type="submit" className="login_button" onClick={submitHandler}>Sign in</button>
            </div>
            {
                isLoading &&
                <FullScreenLoader />
            }

        </div>
    )
}

export default Login