import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Button, Modal } from 'react-bootstrap'
import AuthContext from '../../../store/auth-context';
import API from '../../../utils/API';
import './CreateSessionModal.css';
import { Form } from 'react-bootstrap';
import RadioButton from '../../RadioButton/RadioButton';

function CreateSessionModal(props) {
    const [clientList, setClientList] = useState([]);
    const [clientId, setClientId] = useState();
    const [planForClient, setPlanForClient] = useState();
    const [typeSet, setTypeSet] = useState();
    const authCtx = useContext(AuthContext);
    const newOptions = [
        "Rehab Session",
        "Team Meeting",
        "Clinical Meeting",
        "Cancellation",
        "Billable Work"
    ]

    useEffect(() => {
        fetchListOfClients()
    }, []);

    const save = () => {
        if (clientId != null && clientId != "" &&
            typeSet != null && typeSet != "" &&
            planForClient != null && planForClient != "") {
            const body = {
                clientid: clientId,
                type: typeSet,
                planid: planForClient
            }
            createNewSession(body)
        }
    }

    const exit = () => {
        setPlanForClient(null)
        setClientId(null)
        setTypeSet(null)
        props.setShowModal(!props.show)
    }

    const fetchListOfClients = async () => {
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get("/v2/clients/provider", config);
            let statusCode = response.data.status
            let sessionsResponse = response.data.message.result.clients
            if (statusCode == 200 && sessionsResponse != null && sessionsResponse.length > 0) {
                setClientList(response.data.message.result.clients)
            } else if (statusCode >= 400 && statusCode < 500) {
            }
        } catch (e) {
        }
    }

    const createNewSession = async (bodyData) => {
        //console.log(bodyData)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.post("/v2/sessions/note/create", bodyData, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                //console.log("session created")
                props.sessionCreated(response.data.message.result.snid)
            } else if (statusCode >= 400 && statusCode < 500) {
            }
        } catch (e) {
        }

    }

    const clientSelected = (event) => {
        setClientId(event.target.value)
        const clientIdC = event.target.value
        if (clientIdC != null && clientIdC != 'Select Client...') {
            for (const client of clientList) {
                if (client.clientid == clientIdC) {
                    if (client.plans.length == 1) {
                        setPlanForClient(client.plans[0].planid)
                    } else {
                        setPlanForClient(null)
                    }
                }
            }
        } else {
            setPlanForClient(null)
        }
    }

    const handleSetChange = (event) => {
        setTypeSet(event.target.value)
    };

    const planClientSelected = (event) => {
        setPlanForClient(event.target.value)
    }

    const getPlansForClient = () => {
        if (clientId != null && clientId != 'Select Client...') {
            for (const client of clientList) {
                if (client.clientid == clientId) {
                    const plans = client.plans;
                    if (plans.length > 1) {
                        return <Fragment>
                            <br />
                            <Form.Select
                                aria-label="Provider Title"
                                onChange={(e) => planClientSelected(e)}>
                                <option> Select Treatment Plan...</option>
                                {plans.map((value, index) =>
                                    <option key={value.planid} value={value.planid}>{value.planlabel}</option>
                                )}
                            </Form.Select>
                        </Fragment>
                    } else {
                        return <div></div>
                    }
                }
            }
        } else {
            return <div></div>
        }

    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"CREATE Note"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Create new session note for: </p>
                <Form.Select
                    aria-label="Provider Title"
                    onChange={(e) => clientSelected(e)}>
                    <option>Select Client...</option>
                    {clientList.map((value, index) =>
                        <option key={value.clientid} value={value.clientid}>{value.last}, {value.first}</option>
                    )}
                </Form.Select>
                {getPlansForClient()}
                <br />
                <p>Select one of the following: </p>
                <div className="covidForm__radio__container" onChange={handleSetChange}>
                    {newOptions.map((value, index) => (
                        <RadioButton
                            text={value}
                            // value={21 + index}
                            value={value}
                            name={"client123"}
                        />
                    ))}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={exit} variant="secondary">{"cancel"}</Button>
                <Button onClick={save}>{"create"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateSessionModal;
