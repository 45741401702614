import React from 'react'
import './HorizontalDivider.css'

function HorizontalDivider() {
    return (
        <div>
            <hr className="divider__style"/>
        </div>
    )
}

export default HorizontalDivider
