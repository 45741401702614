import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import Toolbar from '../../components/Toolbar/Toolbar'
import AuthContext from '../../store/auth-context';
import emptyListIcon from '../../assets/emptylist.svg'
import API from '../../utils/API';
import NotificationList from './NotificationList';


function Notifications() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const authCtx = useContext(AuthContext);


    useEffect(() => {
        fetchNotificationData()
    }, []);

    const fetchNotificationData = async () => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }

            let response = await API.get("v2/notifications/provider?filter=all&limit=100", config);
            if (response.data.message.result.notifications != null &&
                response.data.message.result.notifications.length > 0) {
                setNotifications(response.data.message.result.notifications)
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const backNavigationHandler = async () => {
        history.push("/")
        history.replace()
    }

    return (
        <Fragment>
            {
                isLoading &&
                <FullScreenLoader />
            }
            <Toolbar
                enableBackNavigation={true}
                titleScreen={"Notifications"}
                backNavigationHandler={backNavigationHandler}
            />
            {
                !isLoading &&
                notifications.length == 0 &&
                <div className='empty__container'>
                    <img src={emptyListIcon} className="empty__icon" />
                    <p>You currently have no notifications</p>
                </div>
            }
            {
                notifications.length > 0 &&
                <NotificationList
                    notifications={notifications}
                />
            }

        </Fragment>

    )
}

export default Notifications