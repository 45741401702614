import React, { useEffect, useContext } from 'react'
import QueryString from 'qs'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import logoGenesis from '../../assets/logogenesis.jpeg'
import AuthContext from '../../store/auth-context';
import UserContext from '../../store/user-context';
import API from '../../utils/API';

function SingleSignOn() {
    const location = useLocation()
    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext)

    useEffect(() => {
        let token = QueryString.parse(location.search, { ignoreQueryPrefix: true }).user_token
        let name = QueryString.parse(location.search, { ignoreQueryPrefix: true }).user_name
        singOnUser(token, name)
    }, []);

    const singOnUser = async (token, name) => {
        try {
            const bodyData = {
                "username": name,
                "ssotoken": token
            }
            const authHeader = "Bearer " + token
            let config = { headers: { Authorization: authHeader } }
            let response = await API.post(`/v2/users/sso`, bodyData, config);
            let statusCode = response.data.status
            console.log(response)
            if (statusCode == 200) {
                userCtx.updateCovidFormState(true)
                authCtx.onLogin(response.data.message.result.token, name)
            } else {
            }
        } catch (e) {
        }
    }

    return (
        <div>
            <FullScreenLoader />
            <img src={logoGenesis} className="logo_img" />
        </div>
    )
}

export default SingleSignOn