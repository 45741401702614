import React from 'react'
import "./RadioButton.css"

function RadioButton(props) {
    return (
        <label className="radio__container"> {props.text}
            <input type="radio"
                value={props.value}
                name={props.name} />
            <span className="checkmark"></span>
        </label>
    )
}

export default RadioButton
