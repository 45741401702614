import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

function CenteredModal(props) {

    const [modalInput, setModalInput] = useState("");

    const save = () => {
        // Custom remark
        if(modalInput != null && modalInput.length > 0) {
            let customRemark = {
                heading: modalInput,
            }
            props.info.action(true, customRemark)
            setModalInput("")
        } else { // Optional remark
            props.info.action(true)
        }
    }

    const exit = () => {
        props.info.action(false)
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.info.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.info.description}</p>
                {
                    props.info.showInput &&
                    <input
                        type={"Text"}
                        placeholder={props.info.placeholder}
                        value={modalInput}
                        onChange={e => setModalInput(e.target.value)}
                        tag="clientid"
                        formType="text"
                    />

                }

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={exit} variant="secondary">{props.info.cancelButton}</Button>
                <Button onClick={save}>{props.info.confirmButton}</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default CenteredModal
