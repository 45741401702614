import axios from "axios"

export default axios.create({
    // BW*****
    //baseURL: "http://localhost:3000/api/",
    //baseURL: "http://192.168.2.111:3000/api/",
    //baseURL: "https://uat.mygenesislive.com/api/",
    baseURL: "https://mygenesislive.com/api/",
    headers: {
        'Content-Type': 'application/json'
    }
});

/*******************************************************
 * For newer nodejs you may need to use this command
 * before running npm start :
 * export NODE_OPTIONS=--openssl-legacy-provider
 *******************************************************/