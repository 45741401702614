import React from 'react';
import { Button, Modal } from 'react-bootstrap'


function SubmitSessionModal(props) {

    const save = () => {
        props.submitHandler()
    }

    const exit = () => {
        props.setShowSubmitModal(!props.show)
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"Session Note Submission"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure this session note is 100% complete?</p>
                <p>By clicking "Ok" this note will be sent to management for review and will be included into this client’s weekly “Progress Note.” </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={exit} variant="secondary">{"Cancel"}</Button>
                <Button onClick={save}>{"Ok"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SubmitSessionModal;
