import React, { useRef, useContext, useState, useEffect, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import AuthContext from '../../../store/auth-context';
import API from '../../../utils/API';
import Toolbar from '../../../components/Toolbar/Toolbar'
import './EditSession.css';
import InputForm from '../../../components/UI/InputForm/InputForm';
import DatePicker from '../../../components/UI/DatePicker/DatePicker';
import PrimaryButton from '../../../components/Button/PrimaryButton';
import FullScreenLoader from '../../../components/Loaders/FullScreenLoader';
import DropDownMenu from '../../../components/UI/DropDownMenu/DropDownMenu';
import { ToastContainer, toast } from "react-toastify";
import ToastUI from '../../../components/UI/ToastUI/ToastUI';
import CenteredModal from '../../../components/UI/Modal/CenteredModal';
import RemarkList from '../../../components/UI/RemarkList/RemarkList';
import UserContext from '../../../store/user-context';
import SessionPreviewModal from '../../../components/UI/SessionPreviewModal/SessionPreviewModal';
import HistorySessionModal from '../../../components/UI/HistorySessionModal/HistorySessionModal';
import ActivityInput from '../../../components/UI/ActivityInput/ActivityInput';

function EditSession(props) {
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [sessionDescription, setSessionDescription] = useState({});
    const [sessionRemarks, setSessionRemarks] = useState([]);
    const [sessionTitles, setSessionTitles] = useState([]);
    const [sessionPrevious, setSessionPrevious] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [modalInput, setModalInput] = useState("");
    const [modalData, setModalData] = useState({});
    const [showHistorySessionModal, setHistorySessionModal] = useState(false);
    const [showHistorySessionLoader, setHistorySessionLoader] = useState(false);

    const [previousModalDesc, setPreviousModalDesc] = useState({});
    const [previousModalRemark, setPreviousModalRemark] = useState([]);

    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext)
    const history = useHistory();
    const tempRemark = useRef(-1)
    var tempCustomRemark = {}
    var isEditingRemark = false
    var isRemarkOpen = false
    //Autosave Variables
    //var inFocus = false;
    //var setCount = 0;
    var autosavetimer = null;
    var currentRemark = -1
    var lastSave = 0;

    useEffect(() => {
        fetchData(location.state.sessionId)
    }, []);

    const fetchData = async (sessionId) => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/sessions/note/${sessionId}`, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                updateSessionData(response.data.message.result)
                updatePreviousSessions(response.data.message.previous)
                setIsLoading(false)
            } else if (statusCode >= 400 && statusCode < 500) {
                goToLogin()
            }
        } catch (e) {
            setIsLoading(false)
        }
        isEditingRemark = false
        setIsLoaded(true)
    }

    const goToLogin = () => {
        setIsLoading(false)
        history.push("/")
        authCtx.onLogout();
    }

    const updatePreviousSessions = (array) => {
        if (array != null) {
            setSessionPrevious(array)
        }
    }

    const updateSessionData = (result) => {
        if (result.description != null && result.remarks != null) {
            //Add next field
            if (history.location.state.sessionCreated) {
                result.description.next = result.description.to
            }
            setSessionDescription(result.description)
            //Add custom Header
            result.remarks.push({ sncid: 0, snid: result.description.sessionid, index: result.remarks.length, type: 3, heading: "Custom Remark", content: "" })
            setSessionRemarks(result.remarks)
            setSessionTitles(result.titles)
        } else {
            setSessionRemarks(null)
        }

    }

    const descriptionChange = (formType, tag, event) => {
        if (formType == "text") {
            sessionDescription[tag] = event.target.value
        } else if (formType == "date") {
            const date = new Date(event)
            sessionDescription[tag] = date.toISOString()
        } else if (formType == "dropDownMenu") {
            sessionDescription[tag] = Number(event.target.value)
        } else if (tag == "exclude_travel") {
            if (sessionDescription[tag] == 0) {
                sessionDescription[tag] = 1
            } else {
                sessionDescription[tag] = 0
            }
        }
        setSessionDescription((prevState) => ({
            ...prevState,
        }));
    }

    const dropDownUpdatedDefault = (newDefault) => {
        sessionDescription.title = Number(newDefault)
        setSessionDescription((prevState) => ({
            ...prevState,
        }));
    }

    const saveDescription = async () => {
        try {
            let config = { headers: { Authorization: authCtx.authHeader } }
            let response = await API.put(`/v2/sessions/note/description/${sessionDescription.sessionid}`, sessionDescription, config);
            toast.success(
                ({ }) => (<ToastUI title="Description saved" />),
                { position: toast.POSITION.TOP_CENTER, draggable: true }
            )
        } catch (e) {
            toast.error(
                "Error",
                { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
            );
        }
    }

    // On Enter of a Quill Editor.
    const remarkFocusChange = (index) => {
        currentRemark = index
    }

    // On exit of a quill editor
    const remarkFocusOut = (index) => {
        saveRemarkTimer(false);
        saveRemark(index, false);
        currentRemark = -1;
    }

    const saveRemarkOnChangeFocus = (save) => {
        if (save) {
            saveRemark(currentRemark, false)
        }
        setShowModal(false)
    }

    // Handle Starting/Stopping the autosave timer
    const saveRemarkTimer = (state) => {
        clearTimeout(autosavetimer);
        autosavetimer = null;
        if(state) {
            autosavetimer = setTimeout(timedRemarkRun, 10000);
        }
    }

    // Called each time an editor changes
    const timedRemarkSet = (index) => {
        if(isLoaded) {
            saveRemarkTimer(true);
            currentRemark = index;
        }
    }

    // Save the remark and turn off the timer.
    const timedRemarkRun = () => {
        saveRemarkTimer(false);
        saveRemark(currentRemark, false);
    }

    const remarkChange = (type, index, event) => {
        if(isLoaded) {
            if (type == "content") {
                sessionRemarks[index].content = event
                //if (event != "<br/>") {
                    isEditingRemark = true
                //}
            } else if (type == "cue") {
                sessionRemarks[index].cue = event.target.value
                isEditingRemark = true
                remarkFocusChange(index, event)
            } else if (type == "cue2") {
                sessionRemarks[index].cue2 = event.target.value
                isEditingRemark = true
                remarkFocusChange(index, event)
            }
            timedRemarkSet(index);  // Set or extend a timer to do an autosave of the current editor (index)
        }
    }

    const saveRemark = async (index, showSuccessToast) => {
        const remark = sessionRemarks[index]
        try {
            let config = { headers: { Authorization: authCtx.authHeader } }

            /******************************************
             * Code below (caclualting and using isSaved) is to prevent double saves of the same remark
             * It prevents a save of a remark if it is within 200mS of the last save
             * 
             * One event triggered by loss of focus
             * and one event triggered by clicking the save button.
             * These 2 events occur within a few MS of each other
             * Tests show that loss of focus hits before save click occurs
             * 
             * The saved snackbar is shown always if showSuccessToast is true (from the save button)
             * 
             * This is not a great solution but should work for now.
             */
            let now = new Date().getTime();
            let isSaved = (now - lastSave < 200);
            lastSave = now;

            if(!isSaved)
                await API.put(`/v2/sessions/note/remark/${remark.sncid}`, remark, config);
            if (showSuccessToast) {
                saveRemarkTimer(false); // Disable Autosave if present.
                toast.success(
                    ({ }) => (<ToastUI title="Remark saved:" description={remark.heading} />),
                    { position: toast.POSITION.TOP_CENTER, draggable: true, toastId: 8765 }
                )
            }
        } catch (e) {
            console.log(`ERROR SAVING:  ${e}`)
            toast.error(
                "Error",
                { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
            );
        }
        isEditingRemark = false
    }

    const invalidDate = () => {
        const date = new Date(sessionDescription.from)
        const dateTo = new Date(sessionDescription.to)
        const dateNext = sessionDescription.next != null ? new Date(sessionDescription.next) : null

        if (dateNext != null && dateNext - dateTo <= 0) {
            return true
        }

        if (sessionDescription.type == "Cancellation") {
            return false;
        } else if (dateTo - date > 0) {
            return false;
        } else {
            return true;
        }
    }

    const getStartDay = () => {
        const date = new Date(sessionDescription.from)
        const dateTo = new Date(sessionDescription.to)
        const dateNext = sessionDescription.next != null ? new Date(sessionDescription.next) : null

        var diff = Math.abs(dateTo - date);
        var textTimer = "";
        var errorText = "";
        var dateNextError = false;
        let ddExtra = "";
        if (sessionDescription.type == "Cancellation") {
            if (diff == 0) {
                ddExtra = ".  NOTE: Cancellation will not be billed";
            } else {
                ddExtra = ".  NOTE: Cancellation will be billed";
            }
            textTimer = msToTime(diff) + ddExtra;
        } else if (dateTo - date > 0) {
            textTimer = msToTime(diff);
        } else {
            textTimer = "0:00";
            errorText = "Error: "
        }

        if (dateNext != null && dateNext - dateTo <= 0) {
            dateNextError = true
            textTimer = "0:00";
            errorText = "Error: "
        }

        var result = ""
        if (dateNextError) {
            result = "Invalid Next Date";
        } else {
            const day = date.toLocaleDateString("en-US", { weekday: 'long' });
            const month = date.toLocaleDateString("en-US", { month: 'long' });
            const dayNumber = date.toLocaleDateString("en-US", { day: 'numeric' });
            const year = date.toLocaleDateString("en-US", { year: 'numeric' });
            const dateFormatted = day + " " + month + " " + dayNumber + ", " + year
            result = errorText + dateFormatted + " for: " + textTimer;
        }
        return result;
    }

    function msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        mins = mins < 10 ? '0' + mins : mins;
        return hrs + ':' + mins + ' hours';
    }

    const addOptionalRemarkModal = (remarkIndex, heading) => {
        tempRemark.current = remarkIndex

        const remark = sessionRemarks[remarkIndex]
        if (sessionRemarks.length == remarkIndex + 1 && remark.heading == "Custom Remark") {
            const modalInfo = {
                title: "Add custom Remark",
                description: "Custom Heading:",
                cancelButton: "Cancel",
                confirmButton: "Create",
                placeholder: "Name",
                showInput: true,
                action: addOptionalRemark,
                inputValue: modalInput,
                inputAction: setModalInput
            }
            setModalData(modalInfo)
        } else {
            const modalInfo = {
                title: "Add Optional Remark",
                description: "Add this remark to the note.",
                cancelButton: "no",
                confirmButton: "yes",
                action: addOptionalRemark,
            }
            setModalData(modalInfo)
        }
        setShowModal(true)
    }

    const newCustomRemarkIndex = () => {
        const index = sessionRemarks[sessionRemarks.length - 2].index + 1
        return index
    }

    const createNewCustomRemark = (customRemark) => {
        tempCustomRemark = customRemark
        tempCustomRemark.sncid = 0
        tempCustomRemark.snid = sessionDescription.sessionid
        tempCustomRemark.index = newCustomRemarkIndex()
        tempCustomRemark.type = 5
        tempCustomRemark.helpid = 0
        tempCustomRemark.subheading = ""
        tempCustomRemark.content = ""
        tempCustomRemark.cue = 0
        tempCustomRemark.cue2 = 0
        tempCustomRemark.editable = 0
    }

    const addCustomRemarkToList = (remark) => {
        sessionRemarks.splice(sessionRemarks.length - 1, 0, remark);
    }

    const addOptionalRemark = async (add, customRemark) => {
        if (add) {
            var bodyData = {}
            if (customRemark != null) {
                createNewCustomRemark(customRemark)
                bodyData = tempCustomRemark
            } else {
                bodyData = sessionRemarks[tempRemark.current]
                bodyData.type = 4
            }
            try {
                let config = { headers: { Authorization: authCtx.authHeader } }
                let response = await API.post(`/v2/sessions/note/remark/0`, bodyData, config);
                let statusCode = response.data.status
                if (statusCode == 200) {
                    bodyData.sncid = response.data.message.result.sncid
                    if (customRemark != null) addCustomRemarkToList(bodyData)
                } else {
                    bodyData.type = 3
                }
            } catch (e) {
                toast.error("Error Adding remark", { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 });
            }
        }
        setShowModal(false)
    }

    const deleteOptionalRemarkModal = (remarkIndex) => {
        tempRemark.current = remarkIndex
        const modalInfo = {
            title: "Remove Optional Remark",
            description: "Remove this Optional Remark",
            cancelButton: "no",
            confirmButton: "yes",
            action: deleteOptionalRemark
        }
        setModalData(modalInfo)
        setShowModal(true)
    }

    const deleteOptionalRemark = async (remove) => {
        if (remove) {
            var bodyData = sessionRemarks[tempRemark.current]
            try {
                let config = { headers: { Authorization: authCtx.authHeader }, data: bodyData }
                let response = await API.delete(`/v2/sessions/note/remark/0`, config);
                let statusCode = response.data.status
                if (statusCode == 200) {
                    if (bodyData.type == 5) {
                        removeDeletedFromList()
                    } else {
                        bodyData.sncid = 0
                        bodyData.type = 3
                    }
                }
            } catch (e) {
                toast.error(
                    "Error",
                    { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
                );
            }
        }
        setShowModal(false)
    }

    const removeDeletedFromList = () => {
        const items = Array.from(sessionRemarks);
        items.splice(tempRemark.current, 1);
        setSessionRemarks(items)
    }

    function handleOnDragEnd(result) {
        isEditingRemark = false
        if (!result.destination || isRemarkOpen) return;
        const source = sessionRemarks[result.source.index]
        const destination = sessionRemarks[result.destination.index]

        if (source.type == 3 && destination.type == 3) return;
        if (source.type != 5 && destination.type == 3) return;
        if (source.type == 3) return;

        var initIndex = sessionRemarks[0].index

        const items = Array.from(sessionRemarks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        for (let i = 0; i < items.length; i++) {
            items[i].index = initIndex
            initIndex++
        }
        setSessionRemarks(items)
        saveReOrderRemarks(items)
    }

    const saveReOrderRemarks = async (remarks) => {
        const items = Array.from(sessionRemarks);
        items.splice(items.length - 1, 1); // remove custom remark from list
        const body = { remarks: items }
        try {
            let config = { headers: { Authorization: authCtx.authHeader } }
            let response = await API.put(`/v2/sessions/note/order`, body, config);
            if (response.data.status == 200) {
                toast.success(
                    ({ }) => (<ToastUI title="Remark order saved" />),
                    { position: toast.POSITION.TOP_CENTER, draggable: true }
                )
            } else {
                toast.error(
                    "Error saving remark",
                    { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
                );
            }
        } catch (e) {
            toast.error(
                "Error saving remark",
                { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
            );
        }
    }

    const openPreviewDocument = async () => {
        setPreviousModalDesc(sessionDescription)
        setPreviousModalRemark(sessionRemarks)
        setShowPreviewModal(true)
    }

    const openShowPreviousSessionList = async () => {
        setHistorySessionModal(true)
    }

    const fetchPreviousSession = async (sessionId) => {
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/sessions/note/${sessionId}`, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                setPreviousModalDesc(response.data.message.result.description)
                setPreviousModalRemark(response.data.message.result.remarks)

                setHistorySessionLoader(false)
                setShowPreviewModal(true)
            } else if (statusCode >= 400 && statusCode < 500) {
                goToLogin()
            }
        } catch (e) {
            setHistorySessionModal(false)
            setHistorySessionLoader(false)
        }
        isEditingRemark = false
    }

    const sessionSelected = async (id) => {
        fetchPreviousSession(id)
    }

    const backNavigationHandler = async () => {
        if (isEditingRemark) {
            isEditingRemark = false
            const remark = sessionRemarks[currentRemark]
            setModalData({
                title: "Attention",
                description: "You are editing the " + remark.heading + " remark. Remember to save your changes before editing a different remark.",
                cancelButton: "Exit",
                confirmButton: "Save Now",
                action: saveRemarkOnChangeFocus,
            })
            setShowModal(true)
        } else {
            history.push("/")
            history.replace()
        }
    }

    const checked = () => {
    }

    const getUserName = () => {
        var name = sessionDescription.client_last + " " + sessionDescription.client_first
        if (sessionDescription.planlabel != null && sessionDescription.planlabel != "") {
            name = name + ": " + sessionDescription.planlabel
        }
        return name
    }

    return (
        <Fragment>
            <SessionPreviewModal
                show={showPreviewModal}
                setShowPreviewModal={setShowPreviewModal}
                sessionDescription={previousModalDesc}
                sessionRemarks={previousModalRemark}
            />
            <HistorySessionModal
                show={showHistorySessionModal}
                setHistorySessionModal={setHistorySessionModal}
                showLoader={showHistorySessionLoader}
                setHistorySessionLoader={setHistorySessionLoader}
                sessionPrevious={sessionPrevious}
                sessionSelected={sessionSelected}
            />
            <div>
                <CenteredModal
                    show={showModal}
                    info={modalData}
                />
            </div>
            <div>
                <Toolbar
                    enableBackNavigation={true}
                    backNavigationHandler={backNavigationHandler}
                    fixedToolBar={true}
                    titleScreen={"Edit Session"}
                    showNewSessionIcons={false}
                    saveHandler={saveDescription}
                    previewDocument={true}
                    openPreviewDocument={openPreviewDocument}
                    sessionHistory={true}
                    openShowPreviousSessionList={openShowPreviousSessionList}
                />
                <ToastContainer
                    hideProgressBar={false}
                />

                {
                    isLoading &&
                    <FullScreenLoader />
                }
                <div className="form__container">
                    <div className="form_input_container">
                        {sessionDescription && !isLoading &&
                            <div className='description__container'>
                                <p className="form_header_text">Client:</p>
                                <InputForm
                                    type={"Text"}
                                    placeholder={"Client"}
                                    value={getUserName()}
                                    onChange={descriptionChange}
                                    tag="clientid"
                                    formType="text"
                                    readonly="readonly"
                                />
                                <p className="form_header_text">Start:</p>
                                <DatePicker
                                    value={sessionDescription.from}
                                    onChange={descriptionChange}
                                    tag="from"
                                    formType="date"
                                />
                                <p className="form_header_text">End</p>
                                <DatePicker
                                    value={sessionDescription.to}
                                    onChange={descriptionChange}
                                    tag="to"
                                    formType="date"
                                />
                                {history.location.state.sessionCreated &&
                                    <Fragment>
                                        <p className="form_header_text">Next</p>
                                        <DatePicker
                                            value={sessionDescription.next}
                                            onChange={descriptionChange}
                                            tag="next"
                                            formType="date"
                                        />
                                    </Fragment>
                                }
                                <p className="form_header_text">Day and Duration:</p>
                                <InputForm
                                    type={"Text"}
                                    placeholder={"Day & Duration"}
                                    value={getStartDay()}
                                    error={invalidDate()}
                                    onChange={descriptionChange}
                                    tag="Day\Duration"
                                    formType="text"
                                    readonly="readonly"
                                />
                                <div className='travel__time__container'>
                                    <p className="form_header_text">Exclude Travel:</p>
                                    <input
                                        className='radio__container__edit'
                                        type="radio"
                                        value={sessionDescription.exclude_travel}
                                        onChange={checked}
                                        onClick={(e) =>
                                            descriptionChange("", "exclude_travel", e)
                                        }
                                        checked={sessionDescription.exclude_travel === 1}
                                    />
                                </div>
                                <p className="form_header_text">Activity Mileage:</p>
                                <ActivityInput
                                    type={"Text"}
                                    placeholder={"Activity Mileage"}
                                    value={sessionDescription.mileage}
                                    onChange={descriptionChange}
                                    tag="mileage"
                                    formType="text"
                                    exclude={sessionDescription.exclude_activity_mileage}
                                />
                                <p className="form_header_text">Activity Expenses:</p>
                                <ActivityInput
                                    type={"Text"}
                                    placeholder={"Activity Expenses"}
                                    value={sessionDescription.expenses}
                                    onChange={descriptionChange}
                                    tag="expenses"
                                    formType="text"
                                    exclude={sessionDescription.exclude_activity_expense}
                                />
                                <p className="form_header_text">Provider Title:</p>
                                <DropDownMenu
                                    value={sessionDescription.title}
                                    titles={sessionTitles}
                                    onChange={descriptionChange}
                                    dropDownUpdatedDefault={dropDownUpdatedDefault}
                                    tag="title"
                                    formType="dropDownMenu"
                                />
                                <PrimaryButton
                                    disabled={invalidDate()}
                                    title="Save description"
                                    handler={saveDescription}
                                />
                            </div>
                        }

                        {
                            sessionRemarks && !isLoading &&
                            <RemarkList
                                sessionRemarks={sessionRemarks}
                                remarkChange={remarkChange}
                                saveRemark={saveRemark}
                                remarkFocusChange={remarkFocusChange}
                                remarkFocusOut={remarkFocusOut}
                                addOptionalRemarkModal={addOptionalRemarkModal}
                                deleteOptionalRemarkModal={deleteOptionalRemarkModal}
                                handleOnDragEnd={handleOnDragEnd}
                            />
                        }
                    </div>
                    <br /><br /><br /><br />
                </div>
            </div>
        </Fragment>
    )
}

export default EditSession
