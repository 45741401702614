import React, { Fragment, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import Toolbar from '../../components/Toolbar/Toolbar'
import AuthContext from '../../store/auth-context';
import GeneralModal from '../../components/UI/GeneralModal/GeneralModal';
import InputPassword from '../../components/UI/InputPassword/InputPassword';
import "./UpdatePassword.css"
import API from '../../utils/API';
import ToastUI from '../../components/UI/ToastUI/ToastUI';

function UpdatePassword() {
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showGeneralModal, setShowGeneralModal] = useState(false);
    const [generalModalMessage, setGeneralModalMessage] = useState('');

    const backNavigationHandler = async () => {
        history.push("/")
        history.replace()
    }

    const submitHandler = (event) => {
        if (validateNewPassword()) {
            updatePassword()
        } else {
            setShowGeneralModal(true)
        }
    }

    const validateNewPassword = () => {
        if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
            setGeneralModalMessage('Please complete all fields')
            return false
        }

        if (newPassword != confirmPassword) {
            setGeneralModalMessage('New password and confirm password should be the same value.')
            return false
        }
        return true
    }

    const updatePassword = async () => {
        setIsLoading(true)
        try {
            const data = {
                "username": authCtx.userName,
                "password": currentPassword,
                "newpass": newPassword
            }
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.put(`/v2/users/password`, data, config);
            if (response.data.status == 200 && response.data.message.status == 'OK') {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmPassword('')
                setIsLoading(false)
                showSuccessUpdate()
            } else {
                var message = ''
                if (response.data.message.result.message != null) {
                    message = response.data.message.result.message
                } else {
                    message = 'Invalid Password data'
                }
                showLoginError(message)
            }
        } catch (e) {
            showLoginError("NetWork Error")
        }
    }
    const showLoginError = (message) => {
        setIsLoading(false)
        toast.error(
            message,
            { position: toast.POSITION.TOP_CENTER, theme: "colored", draggable: true, toastId: 123 }
        );
    }

    const showSuccessUpdate = () => {
        toast.success(
            ({ }) => (<ToastUI title="Password updated successfully" />),
            { position: toast.POSITION.TOP_CENTER, draggable: true }
        )
    }

    return (
        <Fragment>
            <GeneralModal
                show={showGeneralModal}
                setShowGeneralModal={setShowGeneralModal}
                title={'Invalid Password'}
                message={generalModalMessage}
            />
            <Toolbar
                showNotificationIcon={false}
                enableBackNavigation={true}
                fixedToolBar={false}
                showRefreshIcon={false}
                backNavigationHandler={backNavigationHandler}
                titleScreen={"Update Password"}
            />
            <ToastContainer
                hideProgressBar={false}
            />
            {
                isLoading &&
                <FullScreenLoader />
            }
            {!isLoading &&
                <div className="login_container">
                    <div className="login_form">
                        <p className="password_form_instructions">Current Password:</p>
                        <InputPassword
                            passwordInput={currentPassword}
                            setPasswordInput={setCurrentPassword}
                            placeholder={'Current Password'}
                        />
                        <p className="password_form_instructions">New Password:</p>
                        <InputPassword
                            passwordInput={newPassword}
                            setPasswordInput={setNewPassword}
                            placeholder={'New Password'}
                        />
                        <p className="password_form_instructions">Confirm new password:</p>
                        <InputPassword
                            passwordInput={confirmPassword}
                            setPasswordInput={setConfirmPassword}
                            placeholder={'Confirm new password'}
                        />
                        <br /><br />
                        <button type="submit" className="login_button" onClick={submitHandler}>Update Password</button>

                    </div>
                </div>
            }
        </Fragment>
    )
}

export default UpdatePassword