import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./Toolbar.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SideMenu from '../UI/SideMenu/SideMenu';
import RefreshIcon from '@mui/icons-material/Refresh';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import HistoryIcon from '@mui/icons-material/History';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NotificationActiveIcon from '../../assets/notification_active.svg'
import NotificationIcon from '../../assets/notification.svg'
import Tooltip from "@material-ui/core/Tooltip";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const Toolbar = props => {
    const history = useHistory();
    const toolbarStyle = props.fixedToolBar ? "nav__root nav__root__fixed" : "nav__root"
    const toolbarHeight = 55;

    const submitHandler = (event) => {
        history.push("/")
        history.replace()
    }

    return (
        <div style={{ height: toolbarHeight }} className={toolbarStyle}>
            <input type="checkbox" id="click" />
            <Tooltip title="Back to Home page" placement="top">
                <div className="toolbar__section1">
                    {!props.enableBackNavigation &&
                        <label htmlFor="click" className="burgerButton">
                            <span className="burgerButton__bar" />
                            <span className="burgerButton__bar" />
                            <span className="burgerButton__bar" />
                        </label>}

                    {props.enableBackNavigation &&
                        <ArrowBackIosIcon
                            onClick={props.backNavigationHandler}
                            className="backButtonNavigation" />}
                </div>
            </Tooltip>

            <div className="toolbar__section2">
                <div className="nav__logo">{props.titleScreen}</div>
            </div>

            <div className="toolbar__section3">
                {props.showNotificationIcon && props.notificationIconState &&
                    <Tooltip title="You have Notifications" placement="top">
                        <div className="newSessionIcon__container" onClick={props.goToNotificationScreen}>
                            <img src={NotificationActiveIcon} className="notification__icon" />
                        </div>
                    </Tooltip>
                }
                {props.showNotificationIcon && !props.notificationIconState &&
                    <Tooltip title="You have no new notifications" placement="top">
                        <div className="newSessionIcon__container" data-tooltip-content="hello world" onClick={props.goToNotificationScreen}>
                            <img src={NotificationIcon} className="notification__icon" />
                        </div>
                    </Tooltip>
                }
                {props.showRefreshIcon &&
                    <Tooltip title="Refresh this page" placement="top">
                        <div className="newSessionIcon__container">
                            <RefreshIcon className="newSessionIcon" onClick={props.refreshSessionList} />
                        </div>
                    </Tooltip>
                }
                {props.sessionHistory &&
                    <Tooltip title="View previous 20 Session notes for this client" placement="top">
                        <div className="newSessionIcon__container">
                            <HistoryIcon className="newSessionIcon" onClick={props.openShowPreviousSessionList} />
                        </div>
                    </Tooltip>
                }
                {props.previewDocument &&
                    <Tooltip title="Preview this note (as a document)" placement="top">
                        <div className="newSessionIcon__container">
                            <FindInPageIcon className="newSessionIcon" onClick={props.openPreviewDocument} />
                        </div>
                    </Tooltip>
                }
                {props.selectDate &&
                    <Tooltip title="Select a new Date to display a Service Record for" placement="top">
                        <div className="newSessionIcon__container">
                            <DateRangeIcon className="newSessionIcon" onClick={props.openSelectDate} />
                        </div>
                    </Tooltip>
                }
                {props.pdfServiceRecord &&
                    <Tooltip title="Create & Email Invoice ans Service Record" placement="top">
                        <div className="newSessionIcon__container">
                            <ForwardToInboxIcon className="newSessionIcon" onClick={props.openSendInvoice} />
                        </div>
                    </Tooltip>
                }
            </div>

            <SideMenu
                toolbarHeight={toolbarHeight}
            />
            <div style={{ top: toolbarHeight }} className="menu__background__transparent" />
        </div>
    )
}

export default Toolbar
