import React, { Fragment, useEffect, useState } from 'react';
import './BannerAlert.css';

function BannerAlert(props) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        setMessages(props.messages)
    }, [props.messages]);

    return (
        <>
            {messages && messages.length > 0 ? (
                <div className="banner-alert">
                    {messages.map((messageObj, index) => (
                        <Fragment>
                            <div key={index} className="banner-alert__message">
                                {messageObj.message}
                            </div>
                            <div className="banner-alert__divider"></div>
                        </Fragment>
                    ))}
                </div>
            ) : null}
        </>
    );
}

export default BannerAlert;
