import React, { useRef, useState, useContext } from 'react'
import "./InputPassword.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function InputPassword(props) {
    const [visibility, setVisibility] = useState(false);

    const onClickIcon = (value) => {
        setVisibility(value)
    }

    const getPlaceHolder = () => {
        if(props.placeholder != null) {
            return props.placeholder
        }
        return 'Password'
    }

    return (
        <div className="input__container">
            <input
                value={props.passwordInput}
                onInput={e => props.setPasswordInput(e.target.value)}
                ref={props.passwordInputRef}
                className='input__password'
                type={visibility == true ? 'text' : 'password'}
                placeholder={getPlaceHolder()} />

            {
                visibility == true ?
                    <VisibilityOffIcon className='input__icon' onClick={(e) => onClickIcon(false)}/> :
                    <VisibilityIcon className='input__icon' onClick={(e) => onClickIcon(true)}/> 
            }

        </div>
    )
}

export default InputPassword;
